@use "../../styles/theme" as *;

.projects-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 2rem 0;
}

.title-holder {
  @include section-header-styles;
}

.title {
  font-weight: 400;
  font-size: $heading-medium;
  color: $text-light;
}
