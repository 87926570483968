@import "styles/theme";

.project-content-holder {
  position: relative;
  display: flex;
  height: 100%;
  opacity: 0;
}

.gallery-holder {
  width: 60%;
  @media only screen and (max-width: 700px) {
    min-height: 60%;
  }
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-card-img {
  width: 90%;
  height: auto;
  aspect-ratio: 16/9;
}

.details-holder {
  width: 40%;
  height: 100%;
  padding: 0 1rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1340px) {
  .project-content-holder {
    flex-direction: column;
    overflow-y: auto;
    margin: 0;
  }
  .gallery-holder {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .details-holder {
    width: auto;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    overflow-y: visible;
    align-items: center;
  }
  .project-card-img {
    width: 80%;
    height: auto;
    aspect-ratio: 16/9;
  }
}

@mixin details-content-width {
  width: 90%;
}

.details-title {
  @include details-content-width;

  display: flex;
  color: $accent-light;
}

.details-description {
  @include details-content-width;
  text-align: justify;
  white-space: pre-line;
}

.details-section-header {
  @include details-content-width;
  display: flex;
  color: $accent-light;
}

.tech-stack-holder {
  @include details-content-width;
  display: flex;
  flex-wrap: wrap;
  .header {
    font-size: $text-large;
  }
  .icon {
    width: 40px;
    height: 40px;

    transition: transform 300ms ease-in-out;

    &:hover {
      transform: translateY(-5px);
    }
  }

  div {
    img {
      margin: 0 6px;
    }
  }
}

.links-holder {
  @include details-content-width;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.link-text {
  margin: 0;
}
