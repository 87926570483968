@import "styles/theme";

.carousel {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  .carousel-left-btn-holder {
    visibility: hidden;
  }

  .carousel-right-btn-holder {
    visibility: hidden;
  }

  &:hover {
    .carousel-left-btn-holder {
      visibility: visible;
    }

    .carousel-right-btn-holder {
      visibility: visible;
    }
  }
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #8b8b8b;
  color: #fff;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
}

%carousel-indicator-base {
  height: 4px;
  width: 50px;
  margin: 3px;
  transition: background 0.5s ease;
}

.carousel-indicator {
  @extend %carousel-indicator-base;
  background: $primary-light;
}

.carousel-indicator-active {
  @extend %carousel-indicator-base;
  background: $accent-light;
}

%carousel-btn {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  width: 15%;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
}

.carousel-left-btn-holder {
  @extend %carousel-btn;
  left: 0;
  &:hover {
    .carousel-btn-holder {
      transform: translateX(-5px);
      background-color: rgba($color: $secondary-light, $alpha: 0.6);
    }
  }
}

.carousel-right-btn-holder {
  @extend %carousel-btn;
  right: 0;

  &:hover {
    .carousel-btn-holder {
      transform: translateX(5px);
      background-color: rgba($color: $secondary-light, $alpha: 0.6);
    }
  }
}

.carousel-btn-holder {
  background-color: transparent;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 10%;
  transition: transform 250ms ease-in-out, background-color 250ms ease-in-out;
}

.carousel-btn {
  height: 30px;
  width: 30px;
  fill: transparent;
  stroke: $accent-light;
}

.carousel-left-btn {
  transform: translateX(-10px);
}

.fullscreen-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  z-index: 10;
}
