@import "../../styles/theme";
@import "../../styles/typed";

.homeSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100vw;
  height: 100vh;
}

.name {
  font-family: "Sora", sans-serif;
  font-size: $text-large;
  letter-spacing: 5px;
  color: $accent-light;
}

.title {
  display: inline-block;
}

.titleText {
  display: inline-block;
  font-weight: normal;
  font-size: $heading-large;
  color: $text-light;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .titleText {
    font-size: $heading-medium;
  }
}

.persona {
  color: $text-light;
  &::before {
    color: $accent-light;
    font-weight: normal;
  }
  @include typed(
    "software engineer",
    "web developer",
    "UI designer",
    "tech-enthusiast",
    "gamer",
    (
      type: 0.06,
      pause-typed: 0.5,
      delete: 0.05,
    ),
    (
      caret-width: 2px,
      caret-space: 2px,
    )
  );
}
