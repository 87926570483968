@use "../../styles/theme" as *;

.experiences {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 2rem 0;
}

.title-holder {
  @include section-header-styles;
}

.title {
  font-weight: 400;
  font-size: $heading-medium;
  color: $text-light;
}

.timeline {
  width: 40%;
  padding-left: 0;
  @media only screen and (max-width: 1500px) {
    width: 50%;
  }
  @media only screen and (max-width: 1100px) {
    width: 60%;
  }
  @media only screen and (max-width: 900px) {
    width: 70%;
  }
  @media only screen and (max-width: 700px) {
    width: 90%;
  }
}

.experience-divider {
  width: 80%;
  height: 1px;
  border-radius: 10px;
  border-color: black;
}

.timeline-item {
  position: relative;
  display: flex;
  gap: 1.5rem;

  &:last-child {
    .timeline-content {
      &:before {
        display: none;
      }
    }
  }
}

.timeline-content {
  flex: 1;
  position: relative;
  order: 1;
  padding-left: 1.5rem;
  padding-bottom: 3rem;

  &:before {
    content: "";
    position: absolute;
    right: 100%;
    top: 0;
    height: 100%;
    width: 2px;
    background-color: lightgrey;
  }

  &:after {
    content: "";
    position: absolute;
    left: calc(0px - 13px);
    top: 0;
    width: 20px;
    height: 20px;
    background-color: #fff;
    z-index: 1;
    border: 2px solid lightgrey;
    border-radius: 50%;
  }
}

.timeline-duration {
  text-align: end;
  flex: 0 0 6rem;
  min-width: 0;
  overflow-wrap: break-word;
  padding-bottom: 1rem;
  color: $accent-light;
}
