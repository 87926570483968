@import "styles/theme";

.grid-container {
  display: inline-grid;
  justify-content: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(min(20rem, 100%), 1fr));
  row-gap: 10vh;
  width: 60%;
  height: auto;
}
