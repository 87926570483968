$primary-light: #488b8d;
$secondary-light: #75a5a5;
$accent-light: #005365;

$text-light: #333;
$foreground-text-light: #fff;
$gray-text-light: #788499;

$text-very-small: 0.8em;
$text-small: 1.1em;
$text-medium: 1.3em;
$text-large: 1.5em;

$heading-small: 2em;
$heading-medium: 3.5em;
$heading-large: 5em;

$background-light: #ffffff;

$title-width: 70%;

@mixin section-header-styles {
  width: 70%;
  margin-bottom: 2rem;
  @media only screen and (max-width: 700px) {
    width: 80%;
    font-size: $text-very-small;
  }
}

$bg-svg: url("../assets/bg/bg-cropped.svg");

@font-face {
  font-family: "Sora";
  src: url("../assets/fonts/Sora-Light.ttf") format("ttf");
  font-weight: 300;
  font-style: light;
}

@font-face {
  font-family: "Sora";
  src: url("../assets/fonts/Sora-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Sora";
  src: url("../assets/fonts/Sora-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: bold;
}
