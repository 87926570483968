@import "styles/theme";

.themed-link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
  text-decoration-line: none;
  color: $accent-light;

  &:hover {
    text-decoration-line: underline;
    color: black;
  }
  > * + * {
    margin-left: 4px;
  }
}
