@import "styles/theme";

.project-card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 350px;
  max-width: 100%;
  height: 350px;
  background: #fff;
  border-radius: 20px;
  transition: 0.5s;
  box-shadow: 0 35px 80px rgba(0, 0, 0, 0.15);

  .project-img-holder {
    position: absolute;
    top: 20px;
    width: 300px;
    height: 220px;
    background: #002f3a;
    border-radius: 12px;
    overflow: hidden;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;

    .project-card-img {
      width: 80%;
      height: auto;
      aspect-ratio: 4/3;
    }
  }

  .project-info-holder {
    position: absolute;
    top: 252px;
    width: 90%;
    height: 50px;
    padding: 0 30px;
    text-align: center;
    overflow: hidden;
    transition: 0.5s;

    .project-card-title {
      font-size: 1.5rem;
      font-weight: 700;
      color: $accent-light;
    }

    .project-card-description {
      color: #333;
    }

    .project-card-button {
      position: relative;
      margin-top: 1.5rem;
      color: $foreground-text-light;
      border-radius: 10px;
    }
  }

  &:hover {
    height: 400px;

    .project-img-holder {
      top: -100px;
      scale: 0.75;
      box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
    }

    .project-info-holder {
      top: 130px;
      height: 250px;
    }
  }
}

@media (max-width: 1700px) {
  .project-card {
    width: 300px;
    border-radius: 15px;
    .project-img-holder {
      width: 250px;
      border-radius: 10px;
    }

    .project-info-holder {
      .project-card-description {
        font-size: 0.8rem;
      }
      .project-card-button {
        font-size: 0.9rem;
      }
    }
  }
}

@media (max-width: 480px) {
  .project-card {
    width: 230px;
    border-radius: 15px;
    .project-img-holder {
      width: 185px;
      border-radius: 10px;
    }

    .project-info-holder {
      .project-card-description {
        font-size: 0.8rem;
      }
      .project-card-button {
        font-size: 0.9rem;
      }
    }
  }
}
