@import "styles/theme";

.footer-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $accent-light;
  width: 100%;
  margin-top: 20vh;
}

.social-media-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 3rem;
  > * + * {
    margin-top: 0.1rem;
  }
}

.social-media-name-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.social-media-name {
  color: $secondary-light;
}

.social-media-icons-holder {
  display: flex;
  > * + * {
    margin-left: 2rem;
  }
}

.divider {
  border: 1px solid $gray-text-light;
  width: 90%;
}
.copyright-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  > * + * {
    margin-left: 3rem;
  }
}

.copyright {
  text-align: center;
  color: $gray-text-light;
}
