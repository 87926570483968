@import "theme";

body::before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  pointer-events: none;
  transform: scale(1);
  background-attachment: scroll;
  background-image: $bg-svg;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #ffffff;
}

body {
  background-color: $background-light;
  color: $primary-light;
  font-family: "Sora", sans-serif;
  -webkit-text-size-adjust: none;
  margin: 0;
  padding: 0;

  &.modal-active {
    overflow: hidden;
  }
}

// body.dark-mode {
//   background-color: $background-dark;
//   color: $primary-dark;
//   font-family: "Sora", sans-serif;
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
